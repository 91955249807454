import { ReactNode, useEffect, useState } from "react";

import { ApolloProvider } from "@apollo/client";
import { NoSsr } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { NextPage } from "next";
import { useRouter } from "next/router";

import { ColumnLayout } from "shared/layouts/ColumnLayout";
import { AuthProvider } from "shared/providers/AuthProvider";
import { ColorModeProvider } from "shared/providers/ColorModeProvider";
import { SnackbarProvider } from "shared/toast/SnackbarProvider";

import Layout from "../components/Layout/Layout";
import LayoutWithoutNavbarFleet from "../components/Layout/LayoutWithoutNavbarFleet";
import PageHead from "../components/PageHead";
import client from "../lib/apollo-client";
import { ErrorProvider } from "../providers/ErrorProvider";

import type { AppProps } from "next/app";

import "../styles/globals.css";
import "../styles/print.css";

const routesRequireColumnLayout = ["/login", "/delete-my-data"];
const UNAUTHENTICATED_ROUTES = new Set([
  ...routesRequireColumnLayout,
  "/accept",
  "/signup",
  "/forgot-password",
  "/reset-password",
  "/approve",
  "/verify",
  "/pay",
  "/web",
  "/welcome",
  "/landing1",
  "/requests",
]);

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

let stripePromise: ReturnType<typeof loadStripe>;

function App({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const [LDProvider, setLDProvider] = useState<
    (({ children }: { children: ReactNode }) => JSX.Element) | null
  >(null);

  useEffect(() => {
    async function initializeLD() {
      if (!process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_KEY) return;

      const LDProviderComponent = await asyncWithLDProvider({
        clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_KEY,
        options: {
          streaming: true,
        },
      });
      setLDProvider(() => LDProviderComponent);
    }

    initializeLD();
  }, []);

  if (!LDProvider) return;

  let routeRequiresAuth = true;
  for (const routeToTest of Array.from(UNAUTHENTICATED_ROUTES)) {
    routeRequiresAuth =
      routeRequiresAuth && !router.asPath.startsWith(routeToTest);
  }

  const showColumnLayoutWithLogo = routesRequireColumnLayout.some((route) =>
    router.asPath.includes(route)
  );

  const getLayout =
    Component.getLayout ??
    ((page) => {
      if (routeRequiresAuth) {
        stripePromise =
          stripePromise ??
          loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY as string);
        return (
          <Elements stripe={stripePromise}>
            <Layout>{page}</Layout>
          </Elements>
        );
      } else if (showColumnLayoutWithLogo) {
        return <ColumnLayout showLogo>{page}</ColumnLayout>;
      }

      return <LayoutWithoutNavbarFleet>{page}</LayoutWithoutNavbarFleet>;
    });

  return (
    <NoSsr>
      <PageHead />
      <ColorModeProvider>
        <ErrorProvider>
          <LDProvider>
            <AuthProvider
              routeRequiresAuth={!routeRequiresAuth}
              loginPath="/login"
            >
              <ApolloProvider client={client}>
                <SnackbarProvider>
                  {getLayout(<Component {...pageProps} />)}
                </SnackbarProvider>
              </ApolloProvider>
            </AuthProvider>
          </LDProvider>
        </ErrorProvider>
      </ColorModeProvider>
      {/* Only load analytics when using the default layout */}
      {Component.getLayout ? null : <Analytics />}
      {Component.getLayout ? null : <SpeedInsights />}
    </NoSsr>
  );
}

export default App;
